import React from 'react'
import { priceNotation } from '../../../utils/safe'
import { Trans } from '../../atoms'
import Link from 'next/link'
import MyImage from '../../atoms/MyImage/MyImage'

const ProdottoSideCart = ({ item, prodotto, quantita, isPrenotato }) => {
  return (
    <div className="prodotto-side-cart">
      {/* <img
        className="prodotto-side-cart__image"
        src={prodotto.main_image ? prodotto.main_image : 'https://via.placeholder.com/86x86'}
      /> */}
      <div className="prodotto-side-cart__image-box">
        <Link href={prodotto.url}>
          <MyImage
            width={86}
            height={86}
            src={prodotto.main_image ? prodotto.main_image : 'https://via.placeholder.com/86x86'}
            className={'prodotto-side-cart__image'}
            alt={prodotto.titolo}
            title={prodotto.titolo}
          />
        </Link>
      </div>
      {isPrenotato && (
        <div className="prodotto-side-cart__etichetta">
          <Trans chiave={'Prenotata'} />
        </div>
      )}
      <div className="prodotto-side-cart__main">
        <div>
          <Link href={prodotto.url}>
            <strong>{prodotto.codice}</strong>
          </Link>
          {prodotto?.prodotto_nome && <p className="prodotto-side-cart__nome">{prodotto.prodotto_nome}</p>}
          <p className="prodotto-side-cart__descrizione">
            {prodotto.titolo}
            {prodotto.taglia && (
              <>
                {' '}
                - <span className="prodotto-side-cart__size">{prodotto.taglia?.nome}</span>
              </>
            )}
          </p>
        </div>
        <p className="prodotto-side-cart__data prodotto-side-cart__data--color">
          <span className="prodotto-side-cart__data-label">
            <Trans chiave={'Colore'} />:
          </span>{' '}
          <span className="prodotto-side-cart__data-value">{prodotto.colore?.nome}</span>
        </p>
        <div className="prodotto-side-cart__bottom-row">
          <p className="prodotto-side-cart__data prodotto-side-cart__data--quantity">
            <span className="prodotto-side-cart__data-label">
              <Trans chiave={'Quantita'} />:
            </span>{' '}
            <span className="prodotto-side-cart__data-value">{quantita}</span>
          </p>
          <p className="prodotto-side-cart__data prodotto-side-cart__data--price">
            <span className="prodotto-side-cart__data-value">
              {priceNotation(item?.totale, 3)} €
            </span>
          </p>
        </div>
      </div>
    </div>
  )
}

export default ProdottoSideCart
